var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-submitSupplierInternalPartToProduction mr-2"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "disabled": _vm.part.analysisStatus === '' || _vm.part.analysisStatus === 'IN_PROGRESS' || !_vm.part.material || !_vm.part.technology
    },
    on: {
      "click": _vm.openModal
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-industry ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("SendToProduction")) + " ")]) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    on: {
      "input": _vm.handlerModal
    },
    model: {
      value: _vm.modalSubmitPart,
      callback: function callback($$v) {
        _vm.modalSubmitPart = $$v;
      },
      expression: "modalSubmitPart"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("SendToProduction")))]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pb-2"
  }, [_c('v-card-subtitle', [_c('v-col', [_c('h4', [_vm._v(_vm._s(_vm.$t('QuantityAndOrder')))])])], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('Quantity'),
      "type": "number",
      "outlined": ""
    },
    model: {
      value: _vm.formQuantity,
      callback: function callback($$v) {
        _vm.formQuantity = $$v;
      },
      expression: "formQuantity"
    }
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    ref: "refFormReference",
    attrs: {
      "label": _vm.$t('InternalOrderReference'),
      "disabled": _vm.checkboxNoReference,
      "rules": _vm.rulesInternalReference,
      "outlined": ""
    },
    model: {
      value: _vm.formInternalOrderReference,
      callback: function callback($$v) {
        _vm.formInternalOrderReference = $$v;
      },
      expression: "formInternalOrderReference"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('None')
    },
    on: {
      "change": _vm.checkboxChanged
    },
    model: {
      value: _vm.checkboxNoReference,
      callback: function callback($$v) {
        _vm.checkboxNoReference = $$v;
      },
      expression: "checkboxNoReference"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-card-subtitle', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-col', [_c('h4', [_vm._v(_vm._s(_vm.$t('PrinterSelection')))])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" fas fa-question-circle ")])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("NoPrinterTooltip")))])])], 1)], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "pl-0 py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "disabled": "",
      "label": _vm.$t('Technology'),
      "value": _vm.$t(_vm.part.technology)
    }
  })], 1), _c('v-col', {
    staticClass: "pr-0 py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "disabled": "",
      "label": _vm.$t('Material'),
      "value": _vm.$t(_vm.part.material)
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.itemsPrinterBrand,
      "item-value": "value",
      "item-text": function itemText(item) {
        return item.text.replaceAll('_', ' ');
      },
      "label": _vm.$t('PrinterBrand'),
      "outlined": ""
    },
    on: {
      "change": _vm.printerBrandChanged
    },
    model: {
      value: _vm.formPrinterBrand,
      callback: function callback($$v) {
        _vm.formPrinterBrand = $$v;
      },
      expression: "formPrinterBrand"
    }
  })], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.itemsPrinterModel,
      "item-value": "value",
      "item-text": function itemText(item) {
        return item.text.replaceAll('_', ' ');
      },
      "label": _vm.$t('PrinterModel'),
      "outlined": ""
    },
    model: {
      value: _vm.formPrinterModel,
      callback: function callback($$v) {
        _vm.formPrinterModel = $$v;
      },
      expression: "formPrinterModel"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pa-4"
  }, [_c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": !_vm.formQuantity || !_vm.formPrinterBrand || !_vm.formPrinterModel || !_vm.formInternalOrderReference && !_vm.checkboxNoReference,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submitSupplierInternalPartToProduction
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }