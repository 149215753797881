var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-replaceSupplierInternalPartFile mr-2"
  }, [_vm.uploadManagerOn ? _c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "progressSupplier": _vm.checkProgress,
      "errorSupplier": _vm.listUploadError,
      "successSupplier": _vm.uploadSucceeded
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,.3mf,.step,.stp,.obj,.iges,.igs"
    },
    on: {
      "change": _vm.partsUploadFilesChanges
    }
  }), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-upload ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("ReplacePartFile")) + " ")]) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.uploadInProgress,
      callback: function callback($$v) {
        _vm.uploadInProgress = $$v;
      },
      expression: "uploadInProgress"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-6"
  }, [_c('p', {
    staticClass: "body-1 mb-8 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('UploadingDontLeave')) + " ")]), _c('ProgressSingle', {
    staticClass: "px-12 pb-6",
    attrs: {
      "value": _vm.uploadStatus,
      "error": !!_vm.uploadErrorMessage
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.cancelUploadFile
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }