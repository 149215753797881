<template lang="html" src="./replaceSupplierInternalPartFile.template.vue"></template>

<style lang="scss" src="./replaceSupplierInternalPartFile.scss"></style>

<script>
import { EventBus, ApiErrorParser, UploadManager, ProgressSingle, FileConverter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./replaceSupplierInternalPartFile.i18n');

export default {
  name: 'ReplaceSupplierInternalPartFile',
  components: {
    UploadManager,
    ProgressSingle
  },
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      uploadManagerOn: true,
      analysisStatus: '',
      uploadStatus: 0,
      uploadInProgress: false,
      partFile: '',
      partsEditData: [],
      uploadErrorMessage: null
    };
  },
  created() {
    this.analysisStatus = this.part.analysisStatus;
  },
  mounted() {},
  updated() {
    this.analysisStatus = this.part.analysisStatus;
  },
  methods: {
    /**
     * SELECTOR FOR UPLOAD NEW PART
     */
    openFileSelector() {
      this.cancelUploadFile();
      this.uploadManagerOn = true;
      const fileSelector = this.$refs.fileInput;
      fileSelector.value = '';
      fileSelector.click();
    },
    cancelUploadFile() {
      this.uploadManagerOn = false;
      this.uploadInProgress = false;
      this.uploadStatus = 0;
      this.partFile = '';
      this.partsEditData = [];
    },
    preparePartsEditor() {
      const fileName = this.partFile[0].name.replace(/\.[^/.]+$/, '');
      this.partsEditData.push({
        fileID: 0,
        name: fileName,
        description: '',
        internalReference: '',
      });
      this.uploadStatus = 0;
      this.uploadErrorMessage = null;
      this.dialogPartsFileUploadStatus = true;

      if (!this.partFile) {
        this.uploadPartFile();
      } else {
        this.$apiInstance
          .deleteSupplierInternalPartFile(this.supplierUUID, this.supplierPartUUID)
          .then(
            () => {
              this.uploadPartFile();
            },
            (error) => {
              /**
               * ERROR DELETE PART FILE METADATA
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    /**
     * PREPARE UPLOAD FILE AND CHECK HIS EXTENSION
     */
    partsUploadFilesChanges() {
      const fileSelector = this.$refs.fileInput;
      if (fileSelector.value && fileSelector.files.length > 0) {
        this.partFile = fileSelector.files;
        const files = this.partFile[0].name;
        const regex = /(.stl|.3mf|.step|.stp|.obj|.iges|.igs)$/i;
        if (regex.test(files)) {
          this.preparePartsEditor();
        } else {
          this.$notification.notify('DANGER',this.$t('wrongExt'));
        }
      }
    },
    /**
     * UPLOAD NEW PART
     */
    uploadPartFile() {
      const fileToUpload = this.partFile[this.partsEditData[0].fileID];
      this.uploadInProgress = true;
      if (this.uploadManagerOn) {
        const fileExt = fileToUpload.name.split('.').pop().toLowerCase();
        const self = this;
        if(fileExt === 'stl') {

          /**
           * Convert to BLS
           */
          let buffer = null;
          let fileName = fileToUpload.name;
          fileName = fileName.substring(0, fileName.length - 4);

          const reader = new FileReader();
          reader.onloadend = function() {
            buffer = reader.result;

            FileConverter.convertFile(buffer, 'stl', 'bls').then(newData => {

              const newFileToUpload = new File([newData], fileName + '.bls');

              self.$refs.UploadManagerRef.uploadSupplierPartFile(
                newFileToUpload,
                self.supplierUUID,
                self.supplierPartUUID
              );

            });
          };
          reader.readAsArrayBuffer(fileToUpload);
        } else {
          this.$refs.UploadManagerRef.uploadSupplierPartFile(
            fileToUpload,
            self.supplierUUID,
            self.supplierPartUUID
          );
        }
        this.analysisStatus = 'WAITING';
      }
    },
    uploadSucceeded() {
      this.uploadInProgress = false;
      EventBus.$emit('SupplierInternalPartFileUploaded');
      this.uploadStatus = 0;
    },
    checkProgress(status) {
      this.uploadStatus = status;
    },
    listUploadError(error) {
      this.uploadErrorMessage = error;
    },
  },
};
</script>
