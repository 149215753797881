var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-internalWarehousePart"
  }, [_c('a', {
    ref: "linkWarehouseParts"
  }), _c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "no-gutters": ""
    }
  }, [_vm.part ? _c('span', {
    staticClass: "text-wrap display-1 ma-3 ml-0"
  }, [_vm._v(" " + _vm._s(_vm.part.name) + " ")]) : _vm._e(), _c('v-col', [_c('v-row', {
    staticClass: "mx-0",
    attrs: {
      "justify": "end"
    }
  }, [_vm.part && _vm.printers ? _c('SubmitSupplierInternalPartToProduction', {
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID,
      "printers": _vm.printers
    }
  }) : _vm._e(), _c('DownloadSupplierInternalPartFileButton', {
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID
    }
  }), _c('ReplaceSupplierInternalPartFile', {
    ref: "refReplaceSupplierInternalPartFile",
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID
    }
  }), _vm.part.originalFile ? _c('OriginalFileConverterDialog', {
    staticClass: "mr-2",
    attrs: {
      "color": "info",
      "part": _vm.part
    },
    on: {
      "convertOriginalFile": _vm.startSupplierInternalPartOriginalFileConversion
    }
  }) : _vm._e(), _c('DeleteSupplierInternalPartFileButton', {
    attrs: {
      "part": _vm.part,
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "supplier-part-u-u-i-d": _vm.$route.params.supplierPartUUID
    }
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-0 mb-4"
  }), _c('v-row', {
    staticClass: "mx-0 pa-0 mt-2"
  }, [_c('v-card', {
    staticClass: "card-tabs"
  }, [_c('v-tabs', {
    attrs: {
      "slider-color": "orange",
      "grow": "",
      "slider-size": "4"
    }
  }, _vm._l(_vm.tabs, function (tab, key) {
    return _c('v-tab', {
      key: key,
      staticClass: "text-none",
      attrs: {
        "to": tab.route,
        "ripple": false,
        "exact": ""
      }
    }, [tab.icon ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(tab.name) + " ")], 1);
  }), 1)], 1)], 1), _c('v-row', {
    staticClass: "mx-0 mt-3 mb-0"
  }, [_vm.part._id ? _c('v-tabs-items', {
    staticClass: "tabs-items-content elevation-2"
  }, [_c('router-view', {
    on: {
      "replacePartFile": _vm.replacePartFile
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }