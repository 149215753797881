<template lang="html" src="./submitSupplierInternalPartToProduction.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./submitSupplierInternalPartToProduction.scss"></style>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./submitSupplierInternalPartToProduction.i18n');

export default {
  name: 'SubmitSupplierInternalPartToProduction',
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
    printers: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      modalSubmitPart: false,
      formQuantity: null,
      formInternalOrderReference: null,
      formPrinterBrand: null,
      formPrinterModel: null,
      itemsPrinterBrand: [],
      itemsPrinterModel: [],
      loading: false,
      checkboxNoReference: false,
      rulesInternalReference: [],
    };
  },
  created() {
    this.initForms();
    this.rulesInternalReference = [
      (v) => (v && v.length <= 30) || this.$t('30charMax'),
    ];
  },
  mounted() {},
  updated() {},
  methods: {
    initForms() {
      this.formQuantity = null;
      this.formInternalOrderReference = null;
      this.checkboxNoReference = false;
      this.formPrinterBrand = this.part.manufacturingInformations
        ? this.part.manufacturingInformations.printer.brand
        : null;
      this.formPrinterModel = this.part.manufacturingInformations
        ? this.part.manufacturingInformations.printer.model
        : null;
      this.updateItemsPrinterBrand();
      this.updateItemsPrinterModel();
    },
    checkboxChanged() {
      if (this.checkboxNoReference) {
        this.formInternalOrderReference = null;
        this.$refs.refFormReference.resetValidation();
      }
    },
    handlerModal() {
      if (!this.modalSubmitPart) {
        this.formInternalOrderReference = null;
        this.$refs.refFormReference.resetValidation();
      }
    },
    openModal() {
      this.initForms();
      this.modalSubmitPart = true;
    },
    closeModal() {
      this.modalSubmitPart = false;
    },
    printerBrandChanged() {
      this.formPrinterModel = null;
      this.updateItemsPrinterModel();
    },
    updateItemsPrinterBrand() {
      this.itemsPrinterBrand = [
        {
          text: this.$t('Unspecified'),
          value: null,
        },
      ];
      const printerBrandSet = new Set();
      for (const printer of this.printers) {
        if (
          printer.technology === this.part.technology &&
          !printerBrandSet.has(printer.brand)
        ) {
          printerBrandSet.add(printer.brand);
          this.itemsPrinterBrand.push({
            text: printer.brand,
            value: printer.brand,
          });
        }
      }
    },
    updateItemsPrinterModel() {
      this.itemsPrinterModel = [];
      for (const printer of this.printers) {
        if (
          printer.technology === this.part.technology &&
          printer.brand === this.formPrinterBrand
        ) {
          this.itemsPrinterModel.push({
            text: printer.model,
            value: printer.model,
          });
        }
      }
    },
    submitSupplierInternalPartToProduction() {
      this.loading = true;
      const submitSupplierInternalPartToProductionBody = new this.$BcmModel.SubmitSupplierInternalPartToProductionBody(
        parseInt(this.formQuantity),
        this.formPrinterBrand,
        this.formPrinterModel
      );

      if (this.formInternalOrderReference) {
        submitSupplierInternalPartToProductionBody.internalOrder = this.formInternalOrderReference;
      }

      this.$apiInstance
        .submitSupplierInternalPartToProduction(
          this.supplierUUID,
          this.supplierPartUUID,
          submitSupplierInternalPartToProductionBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('PartSentToProduction'));
            EventBus.$emit('SupplierInternalPartSentToProduction');
            this.modalSubmitPart = false;
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
