<template lang="html" src="./pageInternalWarehousePart.template.vue"></template>

<style lang="scss" src="./pageInternalWarehousePart.scss"></style>

<script>
import {
  ApiErrorParser,
  OriginalFileConverterDialog
} from '@cloudmanufacturingtechnologies/portal-components';

import ReplaceSupplierInternalPartFile from '../../components/replaceSupplierInternalPartFile/ReplaceSupplierInternalPartFile';
import DownloadSupplierInternalPartFileButton from '../../components/downloadSupplierInternalPartFileButton/DownloadSupplierInternalPartFileButton';
import DeleteSupplierInternalPartFileButton from '../../components/deleteSupplierInternalPartFileButton/DeleteSupplierInternalPartFileButton';
import SubmitSupplierInternalPartToProduction from '../../components/submitSupplierInternalPartToProduction/SubmitSupplierInternalPartToProduction';

const i18nData = require('./pageInternalWarehousePart.i18n');

export default {
  name: 'PageInternalWarehousePart',
  components: {
    ReplaceSupplierInternalPartFile,
    DownloadSupplierInternalPartFileButton,
    DeleteSupplierInternalPartFileButton,
    OriginalFileConverterDialog,
    SubmitSupplierInternalPartToProduction,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: {},
      printers: [],
      tabs: [
        {
          name: this.$t('Overview'),
          route: { name: 'InternalWarehousePartOverview'},
          icon: '',
        },
        {
          name: this.$t('Production'),
          route: { name: 'InternalWarehousePartProduction'},
          icon: '',
        },
      ]
    };
  },
  created() {
    this.getSupplierInternalPart();
    this.getSupplierPrinters();
  },
  methods: {
    getSupplierInternalPart() {
      this.part = {};
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          // We must set attributes to null otherwise Vue doesn't detect changes and thus doesn't update components
          if (!data.material) {
            data.material = null;
          }
          if (!data.technology) {
            data.technology = null;
          }
          this.part = data;
          if (
            this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING'
            || (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.fileConversionStatus === 'IDLE'))
          ) {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(2000);
            }, 2000);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    getSupplierPrinters() {
      this.$apiInstance
        .getSupplierPrinters(this.$route.params.supplierUUID)
        .then((data) => {
          this.printers = data;
        });
    },
    checkSupplierInternalPartAnalysisStatus(nextTimeout) {
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          this.part.analysisStatus = data.analysisStatus;
          this.part.fileConversionStatus = data.fileConversionStatus;
          if (
            this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING'
            || (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.part.fileConversionStatus === 'IDLE'))
          ) {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(
                Math.min(16000, nextTimeout * 2)
              );
            }, nextTimeout);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    replacePartFile() {
      this.$refs.refReplaceSupplierInternalPartFile.openFileSelector();
    },
    startSupplierInternalPartOriginalFileConversion(parameters) {
      const originalFileConversionParametersBody = this.$BcmModel.OriginalFileConversionParametersBody.constructFromObject(parameters);

      this.$apiInstance.startSupplierInternalPartOriginalFileConversion(
        this.$route.params.supplierUUID,
        this.$route.params.supplierPartUUID,
        originalFileConversionParametersBody
      ).then(response => {
        this.$notification.notify('SUCCESS',this.$t('OriginalFileConversionWillRestart'));
        this.getSupplierInternalPart();
      }).catch(error => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
      });
    },
  },
};
</script>
