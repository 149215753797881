<template lang="html" src="./deleteSupplierInternalPartFileButton.template.vue">
  <!--  -->
</template>

<style lang="scss" src="./deleteSupplierInternalPartFileButton.scss"></style>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./deleteSupplierInternalPartFileButton.i18n');

export default {
  name: 'DeleteSupplierInternalPartFileButton',
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      modalDeletePart: false,
      deleteText: '',
      deleteTextForm: '',
      loading: false,
    };
  },
  created() {
    this.deleteText = this.$t('Delete').toLowerCase();
  },
  mounted() {},
  methods: {
    openModalDeletion() {
      this.checkboxUnderstood = false;
      this.modalDeletePart = true;
    },
    cancel() {
      this.modalDeletePart = false;
    },
    deleteSupplierInternalPart() {
      this.loading = true;
      this.$apiInstance
        .deleteSupplierInternalPart(this.supplierUUID, this.supplierPartUUID)
        .then(
          (partName) => {
            this.$notification.notify('SUCCESS',this.$t('PartDeleted', { partName: partName }));
            EventBus.$emit('PartDeleted');
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
