var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-deleteSupplierInternalPartFileButton"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.openModalDeletion
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-trash ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("DeletePart")) + " ")]) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "450px"
    },
    model: {
      value: _vm.modalDeletePart,
      callback: function callback($$v) {
        _vm.modalDeletePart = $$v;
      },
      expression: "modalDeletePart"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("DeletePart")) + "?")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-1 pt-4 text-justify"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("DeletePartText1")))]), _c('p', [_vm._v(_vm._s(_vm.$t("DeletePartText2")))]), _c('p', [_vm._v(_vm._s(_vm.$t("DeletePartText3")))]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('EnterDelete')))]), _c('v-text-field', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.deleteTextForm,
      callback: function callback($$v) {
        _vm.deleteTextForm = $$v;
      },
      expression: "deleteTextForm"
    }
  }), _c('v-btn', {
    staticClass: "text-none text-justify",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.deleteTextForm !== _vm.deleteText,
      "color": "error",
      "width": "100%"
    },
    on: {
      "click": _vm.deleteSupplierInternalPart
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-trash ")]), _c('span', {
    staticClass: "text-justify"
  }, [_vm._v(_vm._s(_vm.$t('DeletePermanently')))])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-ban ")]), _c('span', [_vm._v(_vm._s(_vm.$t('Cancel')))])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }