<template lang="html" src="./downloadSupplierInternalPartFileButton.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./downloadSupplierInternalPartFileButton.scss"></style>

<script>
import {
  ApiErrorParser,
  BtnMenu,
  DownloadSupplierInternalPartFile,
  DownloadSupplierInternalPartOriginalFile
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./downloadSupplierInternalPartFileButton.i18n');

export default {
  name: 'DownloadSupplierInternalPartFileButton',
  components: {
    BtnMenu
  },
  props: {
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      required: true,
    },
  },
  watch: {
    part: {
      handler: function(val, oldVal) {
        this.updateDownloadChoices();
      }
    },
    deep: true
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      downloadChoices: [],
      partFileDataURL: {},
      fileDownloadName: '',
      partFileMD5: null,
      loadingFile: false,
    };
  },
  created() {
    this.updateDownloadChoices();
  },
  mounted() {},
  methods: {
    updateDownloadChoices() {
      this.downloadChoices = [
        {
          text: '.STL',
          value: 'stl'
        },
        {
          text: '.3MF',
          value: '3mf'
        }
      ];

      if(this.part.originalFile) {
        this.downloadChoices.splice(
          0,0,
          {
            text: `.${this.part.originalFile.extension.toUpperCase()}`,
            value: this.part.originalFile.extension   
          }
        );
      }
    },
    /**
     * GET PART FILE METADATA
     */
    getPartFile(extension = null) {

      extension = extension || 'stl';

      this.fileDownloadName = this.part.name + '.' + extension;
      const button = this.$refs.downloadFileButton;
      this.loadingFile = true;

      if(!this.partFileDataURL[extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) { 
          [promise,req] = DownloadSupplierInternalPartOriginalFile.downloadSupplierInternalPartOriginalFile(
            this.$apiInstance,
            this.supplierUUID,
            this.supplierPartUUID,
          );
        } else {
          [promise, req] = DownloadSupplierInternalPartFile.downloadSupplierInternalPartFile(
            this.$apiInstance,
            this.supplierUUID,
            this.supplierPartUUID,
            extension
          );
        }

        this.$downloadProgress.addDownload(req, this.fileDownloadName, promise);

        promise.then((response) => {
          const byteArray = new Uint8Array(response.buffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          this.partFileDataURL[extension] = URL.createObjectURL(blob);
          button.href = this.partFileDataURL[extension];
          this.loadingFile = false;
          setTimeout(() => {
            button.click();
          }, 250);
        });

      } else {

        button.href = this.partFileDataURL[extension];
        setTimeout(() => {
          button.click();
          this.loadingFile = false;
        }, 1000);

      }
    },
  },
};
</script>
