var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-downloadSupplierInternalPartFileButton"
  }, [_c('a', {
    ref: "downloadFileButton",
    staticClass: "d-none",
    attrs: {
      "download": _vm.fileDownloadName
    }
  }), _c('BtnMenu', {
    staticClass: "mr-2 text-none",
    attrs: {
      "choices": _vm.downloadChoices,
      "color": "info",
      "disabled": !_vm.part || _vm.loadingFile,
      "loading": _vm.loadingFile
    },
    on: {
      "click": _vm.getPartFile
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-download ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }